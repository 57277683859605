import React, { Component } from "react";
import {
  Button,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Spinner
} from 'reactstrap';
import API from "../../api";
import NotifToast from "./NotifToast";



export default class ConfirmModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: this.props.open,
      error: {},
      buttonLoading: false,
      notif: false,
      status: false,
    }
  }

  componentDidMount() {
    
  }

  onAction() {
    this.setState({ buttonLoading: true });

    if (this.props?.method === 'PUT') {
      this.onUpdate();
    }
    else {
      this.onDelete();
    }
  }

  onUpdate() {
    API.put(this.props.url, this.props.body, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        this.onSuccess(resp);
      })
      .catch(err => {
        alert(err)
      })
  }

  onDelete() {
    API.delete(this.props.url, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        this.onSuccess(resp);
      })
      .catch(err => {
        alert(err)
      })
  }

  onSuccess(resp) {
    const result = resp.data;
    this.props.close()
    this.setState({
      buttonLoading: false,
      status: result.status,
      notif: true,
    });

    setTimeout(() => {
      this.setState({
        notif: false,
      });
    }, 3000)

    if (this.props.afterSubmit) {
      this.props.afterSubmit();
    }
  }


  render() {
    return (
      <>
        <Modal isOpen={this.props.open} toggle={() => this.props.close()}>
          <ModalHeader toggle={() => this.props.close()}>Confirmation</ModalHeader>
          <ModalBody className="text-center">
            <h5>{this.props.text ? this.props.text : 'Are you Sure ?'}</h5>
          </ModalBody>
          <ModalFooter>
            <Button color="success" onClick={() => this.onAction()}>
              {!this.state.buttonLoading ? 'Yes' : <Spinner size="sm">Loading...</Spinner>}
            </Button>
            <Button color="danger" onClick={() => this.props.close()}>No</Button>
          </ModalFooter>
        </Modal>
        <NotifToast
          show={this.state.notif}
          close={() => this.setState({ notif: false })}
          title="Success"
          message="Data Has Been Successfully Processed"
          status={this.state.status}
        />
      </>
    )
  }
}