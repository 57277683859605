import React, { Component } from 'react';
import API from '../../api'
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Spinner,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import MappingRole from './mappingRole';
import { Link } from 'react-router-dom';
import NotifToast from '../../components/notificationModal/NotifToast';

const init_body = {
  role_id_reff: null,
  name: '',
  group_name: '',
}

export default class addPartnerUserCustom extends Component {

  constructor(props) {
    super(props);
    this.state = {
      openModalAddRole: false,
      id: null,
      username: '',
      password: '',
      confPassword: '',
      manId: '',
      roleId: '',
      body: init_body,
      manufacturerOptions: [],
      roleOptions: [],
      manufacturerId: null,
      error: {},
      disabled: true,
      buttonLoading: false,
      notif: {
        show: false,
        status: true,
        message: '',
        urlRedirection: '',
      },
      showManageRoles: false,
      group: 'dashboard_partner'
    }
  }

  componentDidMount() {
    this.getPartnerUsers();
    // this.getRole()
  }

  getRole(id) {
    //set showManageRoles to false
    this.setState({ showManageRoles: false });
    API.get(`role/${id}`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        const result = resp.data;
        // this.setState({ buttonLoading: false });
        this.setState({
          id,
          roleId: id,
          body: {
            name: result.data.name,
            group_name: result.data.group_name,
            role_id_reff: id
          },
          group: result.data.group_name,
          group_name: result.data.group_name,
          role_id_reff: id
        });

      })
      .catch(err => {
        alert(err)
      })
      .finally(() => {
        this.setState({ showManageRoles: true, group: this.state.body.group_name, body: { role_id_reff: id } });
      })
  }

  async getPartnerUsers() {
    let roleOptions = await API.get('role?option=true&group=dashboard_partner', { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
    let manufacturerOptions = await API.get('manufacturer/options', { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
    this.setState({
      manufacturerOptions: manufacturerOptions.data,
      roleOptions: roleOptions.data.data,
      manufacturerId: manufacturerOptions.data[0].manufacturer_id
    })
  }
  onChange = (e) => {
    const newBody = this.state
    newBody[e.target.name] = e.target.value

    const error = this.state.error;
    if (!e.target.value) {
      error[e.target.name] = 'Field is Required'
    }
    else {
      delete error[e.target.name]
    }

    this.setState({
      body: newBody,
      newBody,
      error: error
    });

    this.onValidation()
  }

  onValidation() {
    const asArray = Object.entries(this.state.body);
    const filtered = asArray.filter(([key, value]) => value === '');
    const result = filtered.length ? true : false

    this.setState({
      disabled: result,
    });

    return result
  }

  onSubmit() {
    console.log({
      username: this.state.username,
      password: this.state.password,
      manId: this.state.manufacturerId,
      roleId: this.state.roleId
    })
    if (this.state.username && this.state.password && this.state.password === this.state.confPassword && this.state.manufacturerId && this.state.roleId) {
      API.post('partner/users',
        JSON.stringify({
          username: this.state.username,
          password: this.state.password,
          manId: this.state.manufacturerId,
          roleId: this.state.roleId
        }),
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      )
        .then(async res => {
          if (res && res.data) {
            if (res.data.status) {
              alert('This partner user was successfully created')
              this.setState({ manufacturerId: null, username: '', password: '', confPassword: '' })
            } else {
              alert(res.data.message)
            }
          }
        })
    } else {
      alert('Please fill all fields/match your confirm password')
    }
  }

  onSubmitAddRole() {
    this.setState({
      // buttonLoading: true,
      notif: { show: true, title: 'Processing ...' }
    });
    let bodyData = {
      role_id_reff: this.state.body.role_id_reff,
      name: this.state.body.name,
      group_name: this.state.body.group_name
    }

    // alert(JSON.stringify(this.state.body)); return;
    API.post('role', bodyData, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        const result = resp.data;
        // this.setState({ buttonLoading: false });
        this.setState({
          body: {},
          notif: {
            title: 'Success',
            message: result.message,
          }
        });

        setTimeout(() => {
          this.setState({
            openModalAddRole: false,
            notif: { show: false }
          });
          if (result.data?.insertId) {
            this.getPartnerUsers()
          }
        }, 1500);
      })
      .catch(err => {
        this.setState({
          body: {},
          notif: {
            title: 'Failed',
            message: err,
          }
        });
      })
  }

  render() {
    return (
      <div className="content">
        <Modal isOpen={this.state.openModalAddRole}
          toggle={() => this.setState({ openModalAddRole: !this.state.openModalAddRole })}
          modalTransition={{ timeout: 500 }}>
          <ModalHeader>
            <h5> Add Role Partner</h5>
          </ModalHeader>
          <ModalBody>
            <div className='row'>
              <Form>
                <FormGroup>
                  <Label for="exampleEmail">
                    Role Name Refference
                  </Label>
                  <select className='form-control' onChange={(e) => { this.getRole(e.target.value) }}>
                    <option>Choose</option>
                    {
                      this.state.roleOptions.map((item, index) => {
                        return (
                          <option value={item.id}>{item.name}</option>
                        )
                      })
                    }
                  </select>
                  <FormFeedback >{this.state.error['name']}</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for="exampleEmail">
                    Name
                  </Label>
                  <Input
                    id="name"
                    name="name"
                    placeholder="Enter Name"
                    type="text"
                    onChange={this.onChange}
                    required
                    invalid={this.state.error['name'] ? true : false}
                  />
                  <FormFeedback >{this.state.error['name']}</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label >
                    Group Name
                  </Label>
                  <Input
                    id="group_name"
                    name="group_name"
                    type="select"
                    onChange={this.onChange}
                    invalid={this.state.error['group_name'] ? true : false}
                    value={this.state.body.group_name}
                  >
                    <option value={''}>Choose</option>
                    <option value={'dashboard_app'}>Dashboard App</option>
                    <option value={'dashboard_partner'}>Dashboard Partner</option>
                  </Input>
                  <FormFeedback >{this.state.error['group_name']}</FormFeedback>
                </FormGroup>
                <Button color="info" onClick={() => this.onSubmitAddRole()}>
                  {!this.state.buttonLoading ? 'Submit' : <Spinner size="sm">Loading...</Spinner>}
                </Button>
              </Form>
            </div>
          </ModalBody>
        </Modal>
        <div className="animated fadeIn">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <strong className="card-title">Add Partner User</strong>
                  {/* <NavLink href="/user/add" > */}
                  <div className="float-right">
                    <Link to="/partner/users">
                      <button
                        color="info"
                        size="sm"
                        className="btn btn-outline-link"
                      >
                        <i className='fa fa-arrow-left'></i>
                      </button>
                    </Link>

                  </div>
                  {/* </NavLink> */}
                </div>
                <div className="card-body">
                  <Form>
                    <FormGroup>
                      <Label for="exampleEmail">
                        Select Manufacturer
                      </Label>
                      <select className='form-control' onChange={(e) => { this.setState({ manufacturerId: e.target.value }) }}>
                        {
                          this.state.manufacturerOptions.length > 0
                            ?
                            this.state.manufacturerOptions.map((item, index) => {
                              return (
                                <option value={item.manufacturer_id}>{item.name}</option>
                              )
                            })
                            :
                            <option>-</option>
                        }
                      </select>
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleEmail">
                        Username
                      </Label>
                      <Input
                        id="username"
                        name="username"
                        placeholder="Enter username"
                        type="text"
                        onChange={this.onChange}
                        invalid={this.state.error['username'] ? true : false}
                      />
                      <FormFeedback >{this.state.error['username']}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleEmail">
                        Password
                      </Label>
                      <Input
                        id="password"
                        name="password"
                        placeholder="Enter password"
                        type="password"
                        onChange={this.onChange}
                        invalid={this.state.error['password'] ? true : false}
                      />
                      <FormFeedback >{this.state.error['password']}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleEmail">
                        Confirm Password
                      </Label>
                      <Input
                        id="confPassword"
                        name="confPassword"
                        placeholder="Enter password"
                        type="password"
                        onChange={this.onChange}
                        invalid={this.state.error['confPassword'] ? true : false}
                      /> {
                        this.state.confPassword !== this.state.password
                          ?
                          <span>Confirm password is not matched</span>
                          :
                          null
                      }
                      <FormFeedback >{this.state.error['confPassword']}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleEmail">
                        Role Akses
                      </Label>
                      <select className='form-control' onChange={(e) => { this.getRole(e.target.value) }}>
                        <option>Choose</option>
                        {
                          this.state.roleOptions.map((item, index) => {
                            return (
                              <option value={item.id}>{item.name}</option>
                            )
                          })
                        }
                      </select>
                      <Link color="success" onClick={() => this.setState({ openModalAddRole: !this.state.openModalAddRole })}>
                      Add Role
                      </Link>

                      <FormFeedback >{this.state.error['name']}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label >
                        Group Name
                      </Label>
                      <Input
                        id="group_name"
                        name="group_name"
                        type="select"
                        onChange={this.onChange}
                        value={this.state.body.group_name}
                        invalid={this.state.error['group_name'] ? true : false}
                        disabled={true}
                      >
                        <option value={''}>Choose</option>
                        <option value={'dashboard_app'}>Dashboard App</option>
                        <option value={'dashboard_partner'}>Dashboard Partner</option>
                      </Input>
                      <FormFeedback >{this.state.error['group_name']}</FormFeedback>
                    </FormGroup>
                    <Button color="info" onClick={() => this.onSubmit()} >
                      {!this.state.buttonLoading ? 'Submit' : <Spinner size="sm">Loading...</Spinner>}
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
          </div>
          {this.state.showManageRoles && (
            <MappingRole id={this.state.id} readonly={false} group={this.state.group} />
          )}
        </div>
        <NotifToast
          show={this.state.notif.show}
          close={() => this.setState({ notif: { show: false } })}
          title={this.state.notif.title}
          message={this.state.notif.message}
        />
      </div>
    )
  }
}