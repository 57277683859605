import moment from 'moment';
import React, { Component } from 'react';
import API from '../../api'
import constants from '../../lib/constants';
import { _hasAccess } from '../../middleware/accessMiddleware'
import { Link } from 'react-router-dom';
import {
    Modal,
    ModalFooter,
    ModalHeader,
    ModalBody
} from 'reactstrap';
import UsersListAccount from './UsersListAccount';

export default class AddPartnerUsers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menuCode: constants.MENU_MANAGE_PARTNER_USERS,
            scope: [],
            resultList: [],
            manufacturerOptions: [],
            roleOptions: [],
            roleId: null,
            manufacturerId: null,
            page: 1,
            items: 10,
            totalPages: 0,
            modal: false,
            showModalAccount: false,
            selectedId: null,
            detailDataUsers: [],
            detailDataUserLoading: false,
            modalType: '',
            search: '*',
            onEnterPressed: false,
            partnerId: null,
        }
    }

    async componentDidMount() {
        this.checkAccess();
        await this.getPartnerUsers();
    }

    async checkAccess() {
        const access = await _hasAccess(this.state.menuCode, 'read');
        console.log(access)
        if (access) {
            let scope = [];
            access.scope.map(item => item.status == 1 ? (scope.push(...[item.name])) : null)

            this.setState({
                scope: scope
            })
            return;
        }

        this.props.history.push(`/unauthorized`);
    }

    async getPartnerUsers() {
        let roleOptions = await API.get('role?option=true&group=dashboard_partner', { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
        let manufacturerOptions = await API.get('manufacturer/options', { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
        let resultList = await API.get('partner/users/' + this.state.page + '/' + this.state.items + '/' + this.state.search, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
        this.setState({
            resultList: resultList.data.result,
            totalPages: resultList.data.totalPages,
            manufacturerOptions: manufacturerOptions.data,
            roleOptions: roleOptions.data.data,
            manufacturerId: manufacturerOptions.data[0].manufacturer_id
        })
    }

    async goToNextPage() {
        if (this.state.page < this.state.totalPages) {
            let token = localStorage.getItem('token');
            // alert(token);
            this.state.page = parseInt(this.state.page) + 1;
            API.get('/partner/users/' + this.state.page + '/' + this.state.items + '/' + this.state.search, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            })
                .then(res => {
                    if (res.data.status) {
                        // alert(JSON.stringify(res.data.result))
                        this.setState({
                            resultList: res.data.result,
                            totalPages: res.data.totalPages
                        })
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            alert('You have reached last page')
        }
    }

    async goToPrevPage() {
        if (this.state.page > 1) {
            let token = localStorage.getItem('token');
            // alert(token);
            this.state.page = parseInt(this.state.page) - 1;
            API.get('/partner/users/' + this.state.page + '/' + this.state.items + '/' + this.state.search, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            })
                .then(res => {
                    if (res.data.status) {
                        // alert(JSON.stringify(res.data.result))
                        this.setState({
                            resultList: res.data.result,
                            totalPages: res.data.totalPages
                        })
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            alert('You have reached first page')
        }
    }

    addPartnerUsers(e) {
        e.preventDefault();
        // alert('test')
        if (this.state.username && this.state.password && this.state.password === this.state.confPassword && this.state.manufacturerId && this.state.roleId) {
            API.post('partner/users',
                JSON.stringify({
                    username: this.state.username,
                    password: this.state.password,
                    manId: this.state.manufacturerId,
                    roleId: this.state.roleId
                }),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                }
            )
                .then(async res => {
                    if (res && res.data) {
                        if (res.data.status) {
                            alert('This partner user was successfully created')
                            this.setState({ modal: !this.state.modal, modalType: '', manufacturerId: null, username: '', password: '', confPassword: '' })
                            await this.getPartnerUsers();
                        } else {
                            alert(res.data.message)
                        }
                    }
                })
        } else {
            alert('Please fill all fields/match your confirm password')
        }
    }

    editPartnerUsers(e, id) {
        e.preventDefault();
        // alert('test')
        if (this.state.username && this.state.manufacturerId) {
            API.put('partner/users/' + id,
                JSON.stringify({
                    username: this.state.username,
                    password: this.state.password ? this.state.password : null,
                    manId: this.state.manufacturerId,
                    roleId: this.state.roleId
                }),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                }
            )
                .then(async res => {
                    if (res && res.data) {
                        if (res.data.status) {
                            alert(res.data.message)
                            // this.setState({
                            //     modal:!this.state.modal
                            // })
                            this.setState({ modal: !this.state.modal, modalType: '', manufacturerId: null, username: '', password: '', confPassword: '' })
                            await this.getPartnerUsers();
                        } else {
                            alert(res.data.message)
                        }
                    }
                })
        } else {
            alert('Please fill all fields/match your confirm password')
        }
    }

    async deletePartnerUser(id) {
        let deleteUserPartner = await API.delete('partner/users/' + id, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
        if (deleteUserPartner.data) {
            if (deleteUserPartner.data.status) {
                alert(deleteUserPartner.data.message)
                this.setState({ modal: !this.state.modal, modalType: '', manufacturerId: null, username: '', password: '', confPassword: '' })
                await this.getPartnerUsers();
            }
        }
    }

    setDropTimeoutEnterEvent() {
        setTimeout(() => { this.setState({ onEnterPressed: !this.state.onEnterPressed }) }, 100)
    }

    showDetailAccount(partnerId) {
        this.setState({
            selectedId: partnerId,
            showModalAccount: true,
            detailDataUserLoading: true,
        }, () => {
            this.getListAccount()
        })
    }

    getListAccount() {
        API.get('partner/users-app/' + this.state.selectedId, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            .then(res => {
                const result = res.data;
                this.setState({
                    detailDataUsers: result.data,
                    detailDataUserLoading: false
                })

            })
            .catch(err => {
                console.log("Error", err)
            })
    }

    render() {
        return (
            <div className='container-fluid'>
                <div className='content'>
                    {/* <LoadingBar onRef={ref => (this.LoadingBar = ref)} /> */}
                    <div className='card'>
                        <div className='card-header'>
                            <div className='row'>
                                <div className='col-md-3 col-6 vertical-middle'>
                                    <strong className='card-title vertical-middle-p'>Partner Users</strong>
                                </div>
                                <div className='col-md-9 col-6 text-right'>
                                    <div className='row'>
                                        <div className='col-4 offset-md-4 text-left'>
                                            <form onSubmit={(e) => { e.preventDefault(); this.getPartnerUsers(); }}>
                                                <input className='form-control' onChange={(e) => { this.setState({ search: e.target.value.length > 0 ? e.target.value : '*', page: 1 }) }} value={this.state.search !== '*' ? this.state.search : ''} type="text" placeholder='Search By Username' />
                                            </form>
                                        </div>
                                        {
                                            this.state.scope.includes('create') ?
                                                <div className='col-4 p-relative'>

                                                    <Link to={`/custom/partner/add`}>
                                                        <button className='btn btn-primary button-center-div'>
                                                            <i className='fa fa-plus mr-2' />Create New Partner User
                                                        </button>
                                                    </Link>
                                                </div>
                                                : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <Modal isOpen={this.state.modal}
                                toggle={() => this.setState({ modal: !this.state.modal, modalType: '', manufacturerId: null, username: '', password: '', confPassword: '' })}
                            // modalTransition={{ timeout: 500 }}
                            >
                                {
                                    this.state.modalType === 'create'
                                        ?
                                        <>
                                            <ModalHeader>
                                                <h5>Create New Partner User</h5>
                                            </ModalHeader>
                                            <ModalBody>
                                                <form onSubmit={(e) => { this.addPartnerUsers(e) }}>
                                                    <div className='form-group'>
                                                        <label>Select Manufacturer</label>
                                                        <select className='form-control' onChange={(e) => { this.setState({ manufacturerId: e.target.value }) }}>
                                                            {
                                                                this.state.manufacturerOptions.length > 0
                                                                    ?
                                                                    this.state.manufacturerOptions.map((item, index) => {
                                                                        return (
                                                                            <option value={item.manufacturer_id}>{item.name}</option>
                                                                        )
                                                                    })
                                                                    :
                                                                    <option>-</option>
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className='form-group'>
                                                        <label>Username</label>
                                                        <input className='form-control' type='username' value={this.state.username} onChange={(e) => { this.setState({ username: e.target.value }) }} />
                                                    </div>
                                                    <div className='form-group'>
                                                        <label>Password</label>
                                                        <input className='form-control' type='password' value={this.state.password} onChange={(e) => { this.setState({ password: e.target.value }) }} />
                                                    </div>
                                                    <div className='form-group'>
                                                        <label>Confirm Password</label>
                                                        <input className='form-control' type='password' value={this.state.confPassword} onChange={(e) => { this.setState({ confPassword: e.target.value }) }} />
                                                        {
                                                            this.state.confPassword !== this.state.password
                                                                ?
                                                                <span>Confirm password is not matched</span>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    <div className='form-group'>
                                                        <label>Role Akses</label>
                                                        <select className='form-control' onChange={(e) => { this.setState({ roleId: e.target.value }) }}>
                                                            <option>Choose</option>
                                                            {
                                                                this.state.roleOptions.map((item, index) => {
                                                                    return (
                                                                        <option value={item.id}>{item.name}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    <button className='btn btn-primary' type='submit'>Submit</button>
                                                </form>
                                            </ModalBody>
                                        </>
                                        : this.state.modalType === 'edit'
                                            ?
                                            <>
                                                <ModalHeader>
                                                    <h5>Edit Partner User</h5>
                                                </ModalHeader>
                                                <ModalBody>
                                                    <form onSubmit={(e) => { this.editPartnerUsers(e, this.state.partnerId) }}>
                                                        <div className='form-group'>
                                                            <label>Select Manufacturer</label>
                                                            <select disabled className='form-control' onChange={(e) => { this.setState({ manufacturerId: e.target.value }) }}>
                                                                {
                                                                    this.state.manufacturerOptions.length > 0
                                                                        ?
                                                                        this.state.manufacturerOptions.map((item, index) => {
                                                                            return (
                                                                                item.manufacturer_id === this.state.manufacturerId
                                                                                    ?
                                                                                    <option selected value={item.manufacturer_id}>{item.name}</option>
                                                                                    :
                                                                                    <option value={item.manufacturer_id}>{item.name}</option>
                                                                            )
                                                                        })
                                                                        :
                                                                        <option>-</option>
                                                                }
                                                            </select>
                                                        </div>
                                                        <div className='form-group'>
                                                            <label>Username</label>
                                                            <input disabled className='form-control' type='username' value={this.state.username} onChange={(e) => { this.setState({ username: e.target.value }) }} />
                                                        </div>
                                                        <div className='form-group'>
                                                            <label>New Password</label>
                                                            <input className='form-control' type='password' value={this.state.password} onChange={(e) => { this.setState({ password: e.target.value }) }} />
                                                        </div>
                                                        <div className='form-group'>
                                                            <label>Confirm New Password</label>
                                                            <input className='form-control' type='password' value={this.state.confPassword} onChange={(e) => { this.setState({ confPassword: e.target.value }) }} />
                                                            {
                                                                this.state.confPassword !== this.state.password
                                                                    ?
                                                                    <span>Confirm password is not matched</span>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className='form-group'>
                                                            <label>Role Akses</label>
                                                            <select className='form-control' onChange={(e) => { this.setState({ roleId: e.target.value }) }}>
                                                                <option>Choose</option>
                                                                {
                                                                    this.state.roleOptions.map((item, index) => {
                                                                        return (
                                                                            <option selected={item.id === this.state.roleId ? true : false} value={item.id}>{item.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                        <button className='btn btn-primary' type='submit'>Submit</button>
                                                    </form>
                                                </ModalBody>
                                            </>
                                            : this.state.modalType === 'delete'
                                                ?
                                                <>
                                                    <ModalHeader>
                                                        <h5>Delete Partner User</h5>
                                                    </ModalHeader>
                                                    <ModalBody>
                                                        <p className='color-black'>Are you sure want to delete <b>"{this.state.username}"</b>?</p>
                                                    </ModalBody>
                                                    <ModalFooter>
                                                        <button onClick={() => { this.deletePartnerUser(this.state.partnerId) }} className='btn btn-danger'>
                                                            Yes
                                                        </button>
                                                        <button onClick={() => { this.setState({ modal: !this.state.modal, modalType: '', manufacturerId: null, username: '', password: '', confPassword: '' }) }} className='btn btn-primary'>
                                                            Cancel
                                                        </button>
                                                    </ModalFooter>
                                                </>
                                                :
                                                <>
                                                    <ModalHeader>
                                                    </ModalHeader>
                                                    <ModalBody>
                                                    </ModalBody>
                                                </>
                                }
                            </Modal>
                            <div class="table-responsive" style={{ minHeight: 530, position: 'relative' }}>
                                <table className='table vert-middle'>
                                    <thead>
                                        <tr>
                                            {/* <th>User ID</th> */}
                                            <th>Username</th>
                                            <th>Manufacturer</th>
                                            <th>Akses</th>
                                            <th>Status</th>
                                            <th>Created At</th>
                                            {
                                                this.state.scope.includes('update') || this.state.scope.includes('delete') ?
                                                    <th className='text-center'>Actions</th>
                                                    : null
                                            }

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.resultList && this.state.resultList.length > 0
                                                ?
                                                this.state.resultList.map((item, index) => {
                                                    return (
                                                        <tr>
                                                            {/* <td>{item.partner_id}</td> */}
                                                            <td>{item.username}</td>
                                                            <td>{item.name}</td>
                                                            <td>{item.role_name}</td>
                                                            <td>Active</td>
                                                            <td width={200}>{moment(item.created_at_user).format('DD-MMM-YYYY HH:mm')}</td>
                                                            {
                                                                this.state.scope.includes('update') || this.state.scope.includes('delete') ?
                                                                    <td width={200} className='text-center'>
                                                                        {
                                                                            this.state.scope.includes('update') ?
                                                                                <button onClick={() => { this.setState({ modal: !this.state.modal, modalType: 'edit', manufacturerId: item.manufacturer_id, roleId: item.role_id, username: item.username, partnerId: item.partner_id }) }} className="text-dark btn btn-sm btn-outline-link" ><li className="fa fa-edit"></li></button>
                                                                                : null
                                                                        }
                                                                        <button onClick={() => this.showDetailAccount(item.partner_id)} className="text-success btn btn-sm btn-outline-link" ><li className="fa fa-users"></li></button>
                                                                        {
                                                                            this.state.scope.includes('delete') ?
                                                                                <button onClick={() => { this.setState({ modal: !this.state.modal, modalType: 'delete', username: item.username, partnerId: item.partner_id }) }} className='text-danger btn btn-sm btn-outline-link'>
                                                                                    <i className='fa fa-trash' />
                                                                                </button>
                                                                                : null
                                                                        }
                                                                    </td>
                                                                    : null
                                                            }
                                                        </tr>
                                                    )
                                                })
                                                :
                                                null
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='card-footer'>
                            <div className='row m-0 pt-2 pagination' style={{}}>
                                <div className='col-md-4 col-3 offset-md-4 offset-6 text-right mb-2'>
                                    <div className='row'>
                                        <div className='col-md-6 vertical-middle px-0 text-center'>
                                            <p className='vertical-middle-p'>Items per page</p>
                                        </div>
                                        <div className='col-md-4'>
                                            <select className='form-control' onChange={(e) => { this.state.items = e.target.value; this.getPartnerUsers(); }}>
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4 col-3 text-right mb-2'>
                                    <div className='row'>
                                        <div className='col-md-1 vertical-middle px-0 text-center p-relative'>
                                            <button className='btn mr-2 button-center-div' onClick={() => { this.goToPrevPage() }}>
                                                <i className='fa fa-chevron-left' />
                                            </button>
                                        </div>
                                        <div className='col-md-3 vertical-middle px-0 text-center'>
                                            <p className='vertical-middle-p'>Showing</p>
                                        </div>
                                        <div className='col-md-3 col-md-3-custom'>
                                            <form onSubmit={(e) => { e.preventDefault(); this.setState({ onEnterPressed: true }); this.setDropTimeoutEnterEvent(); this.getPartnerUsers(); this.pageInput.blur(); }}>
                                                <input onBlur={() => { setTimeout(() => { if (!this.state.onEnterPressed) { this.getPartnerUsers(); } }, 50) }} ref={(input) => { this.pageInput = input; }} className='form-control text-center pt-1' value={this.state.page} onChange={(e) => { this.setState({ page: e.target.value }) }} />
                                            </form>
                                        </div>
                                        <div className='col-md-3 vertical-middle px-0 text-center'>
                                            <p className='vertical-middle-p'>of {this.state.totalPages === 0 ? '' : this.state.totalPages} pages</p>
                                        </div>
                                        <div className='col-md-1 vertical-middle px-0 text-center p-relative'>
                                            <button className='btn ml-2 button-center-div' onClick={() => { this.goToNextPage() }}>
                                                <i className='fa fa-chevron-right' />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <UsersListAccount
                    open={this.state.showModalAccount}
                    close={() => this.setState({ showModalAccount: false })}
                    data={this.state.detailDataUsers}
                    dataLoading={this.state.detailDataUserLoading}
                    refresh={() => this.getList()}
                />
            </div>
        )
    }
}